import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { Home, Settings, Server, Users, Login, MainContainer } from '../containers';
import { Spin, Icon } from 'antd';
import * as firebase from 'firebase';

const loadingIndicator = <Icon type="loading" className="antd-spin" spin />;

export class ContainerRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogged: false,
            loading: true
        };
    }
    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ userLogged: true, loading: false });
            } else {
                this.setState({ userLogged: false, loading: false });
            }
        });
    }
    render() {
        if (this.state.loading) {
            return this.renderLoading();
        }
        if (this.state.userLogged) {
            return this.renderRoutesWithAuth();
        }
        return this.renderRoutesWithoutAuth();
    }
    renderRoutesWithoutAuth = () => {
        return (
            <React.Fragment>
                <Route path="/" exact component={withRouter(Login)} />
            </React.Fragment>
        );
    };
    renderRoutesWithAuth = () => {
        return (
            <React.Fragment>
                <Route path="/" component={withRouter(MainContainer)} />
            </React.Fragment>
        );
    };

    renderLoading = () => {
        return (
            <div className="row justify-center align-center full-height">
                <Spin indicator={loadingIndicator} />
            </div>
        );
    };
}

class AppRouter extends Component {
    render() {
        return (
            <React.Fragment>
                <Route path="/" exact component={withRouter(Home)} />
                <Route path="/settings" component={withRouter(Settings)} />
                <Route path="/server" component={withRouter(Server)} />
                <Route path="/users" component={withRouter(Users)} />
                <Route path="/login" component={withRouter(Login)} />
            </React.Fragment>
        );
    }
}

export default AppRouter;
