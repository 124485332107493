import React, { Component } from 'react'

export default class Settings extends Component {
  render() {
	return (
	  <div>
		<h1>Settings</h1>
	  </div>
	)
  }
}
