import React, { Component } from 'react';
import { Card } from 'antd';
import { TextProgress } from '../components';
import { getCPUUsage } from '../api/DbAPI';

export default class CPUStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cpus: [],
            loading: true
        };
    }
    componentDidMount() {
        let intervalId = setInterval(() => this.getCpus(), 3000);
        this.setState({ intervalId: intervalId });
        this.getCpus();
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    getCpus() {
        getCPUUsage().then(data => {
            this.setState({ cpus: data, loading: false });
        });
    }

    render() {
        return (
            <Card title="CPU Status" loading={this.state.loading}>
                <div className="card-dashboard">{this.renderCPUS()}</div>
            </Card>
        );
    }

    renderCPUS = () => {
        if (this.state.cpus.length <= 0 || this.state.cpus == undefined) return;

        return this.state.cpus.map((element, index) => {
            return <TextProgress key={index} percent={parseInt(element)} title={'CPU ' + index} />;
        });
    };
}
