import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import firebase from '../api/firebase'

export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            loadingSigin: false,
        }
    }
    login(){
        this.setState({loadingSigin: true})
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch((err) =>{
            this.setState({loadingSigin: false})
        });

    }
    render() {
        return (
            <div className="column justify-center align-center full-height">
                <img src={require('../assets/images/logo.png')} alt="logo" className="login-logo"></img>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Username"
                            onChange={(e) => this.setState({email: e.target.value})}
                            />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password"
                            
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox>Remember me</Checkbox>
                        <a className="login-form-forgot" href="">
                            Forgot password
                        </a>
                        <Button type="primary" loading={this.state.loadingSigin} htmlType="submit" className="login-form-button" onClick={() => this.login()}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
