import { types } from '../actions'

const initialState = {
	usersActive: 0,
	usersRegistred: 0,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		
		case types.UPDATE_USERS_ACTIVE:
		return {
			...state,
			usersActive: action.value
		};
		case types.UPDATE_USERS_REGISTRED:
			return {
				...state,
				currentAppId: action.value
			};
		default:
			return state;
	}
};

export default usersReducer;