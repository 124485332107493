import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { CPUStatus, RAMStatus, DiskStatus, ServerInfo } from '../components';

export default class Server extends Component {
    render() {
        return (
            <div>
                <h1>Server</h1>
                <Row gutter={12}>
                    <Col span={12}>
                        <CPUStatus />
                        <ServerInfo />
                    </Col>
                    <Col span={12}>
                        <RAMStatus />
                        <DiskStatus />
                    </Col>
                </Row>
            </div>
        );
    }
}
