import React, { Component } from 'react';
import { Progress, Card, Divider } from 'antd';
import { getRAMUsage } from '../api/DbAPI';
import { TextRow } from '../components';

export default class RAMStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ramStatus: [],
            loading: true
        };
    }
    componentDidMount() {
        let intervalId = setInterval(() => this.getRam(), 3000);
        this.getRam();
        this.setState({ intervalId: intervalId });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }
    getRam() {
        getRAMUsage().then(data => {
            this.setState({ ramStatus: data, loading: false });
        });
    }
    render() {
        return (
            <Card title="RAM Status" loading={this.state.loading}>
                <div className="column">
                    <div className="row">
                        <h3 className="flex-item">
                            {this.convertBytesToMegabytes(this.state.ramStatus.used)}
                        </h3>
                        <h3 className="flex-item text-end">
                            {this.convertBytesToMegabytes(this.state.ramStatus.total)}
                        </h3>
                    </div>
                    <Progress
                        status="active"
                        className="margin-vertical"
                        percent={this.convertNumberToPercentage(
                            this.state.ramStatus.used,
                            this.state.ramStatus.total
                        )}
                    />
                </div>
                <Divider />
                <div className="margin-vertical">
                    <TextRow
                        left="Free"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.free)}
                    />
                    <TextRow
                        left="Available"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.available)}
                    />
                    <TextRow
                        left="Cached"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.cached)}
                    />
                    <TextRow
                        left="Shared"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.shared)}
                    />
                    <TextRow
                        left="Active"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.active)}
                    />
                    <TextRow
                        left="Inactive"
                        right={this.convertBytesToMegabytes(this.state.ramStatus.inactive)}
                    />
                </div>
            </Card>
        );
    }

    convertBytesToMegabytes = value => {
        if (value == undefined) return 0;
        return parseFloat(Number(value) / Math.pow(1024, 2)).toFixed(2) + ' MB';
    };

    convertNumberToPercentage = (value, total) => {
        if (value == undefined || total == undefined) return parseInt(0);
        return parseInt((Number(value) / Number(total)) * 100);
    };
}
