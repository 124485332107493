import { combineReducers } from 'redux';
import appListReducer from './appListReducer';
import usersReducer from './usersReducer';

const initialState = {

};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default combineReducers({
    main: mainReducer,
	appList: appListReducer,
	users: usersReducer
});
