import React, { Component } from 'react'
import { Progress } from 'antd'
export default class TextProgress extends Component {
    render() {
        return (
            <div className="text-circle-container">
                <div {...this.props}>
                    <Progress status="active" type="dashboard" percent={this.props.percent} strokeColor="#123456"/>
                </div>
                <h3 className="text-circle-text text-circle-title ">{this.props.title}</h3>
            </div>
        )
    }
}
