import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DevicesStatus,
    DiskStatus,
    RAMStatus,
    CPUStatus,
    UsersStatus,
    DevicesByModel
} from '../components';
import { Col, Row } from 'antd';

class Home extends Component {
    componentDidMount() {
        console.log(this.props.state);
    }
    render() {
        return (
            <div>
                <h1>Overview</h1>
                <Row gutter={12}>
                    <Col span={12}>
                        <UsersStatus />
                    </Col>
                    <Col span={12}>
                        <DevicesStatus />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <DevicesByModel />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    value: store.main.value,
    state: store
});

const mapDispatchToProps = dispatch => {
    return {
        // clickButton: (value) => { dispatch(clickButton(value)) }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
