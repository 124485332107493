import React, { Component } from 'react';
import { Card } from 'antd';
import { Bar } from 'react-chartjs-2';
import { getDevicesRegisteredByModel, getDevicesActiveByModel } from '../api/DbAPI';
//AC - Alarme comunitario
//PO - Portão
//AU - Automação
//AL - Alarme
//CE - Cerca Eletrica

export default class DevicesByModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chartData: {
                labels: ['Community Alarm', 'Gate', 'Automation', 'Alarm', 'Electric Fence'],
                datasets: []
            }
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData() {
        this.getRegisteredData().then(() => {
            this.getActiveData().then(() => {
                this.setState({ loading: false });
            });
        });
    }

    getRegisteredData() {
        let ac = getDevicesRegisteredByModel('AC');
        let po = getDevicesRegisteredByModel('PO');
        let au = getDevicesRegisteredByModel('AU');
        let al = getDevicesRegisteredByModel('AL');
        let ce = getDevicesRegisteredByModel('CE');
        const promises = [ac, po, au, al, ce];
        return Promise.all(promises).then(data => {
            let chartData = this.state.chartData;
            chartData.datasets.push({
                label: 'Registered',
                backgroundColor: '#222',
                borderColor: '#000',
                borderWidth: 1,
                hoverBackgroundColor: '#444',
                hoverBorderColor: '#555',
                data: data
            });
            this.setState({ chartData: chartData });
        });
    }
    getActiveData() {
        let ac = getDevicesActiveByModel('AC');
        let po = getDevicesActiveByModel('PO');
        let au = getDevicesActiveByModel('AU');
        let al = getDevicesActiveByModel('AL');
        let ce = getDevicesActiveByModel('CE');
        const promises = [ac, po, au, al, ce];
        return Promise.all(promises).then(data => {
            let chartData = this.state.chartData;
            chartData.datasets.push({
                label: 'Active',
                backgroundColor: '#CCC',
                borderColor: '#AAA',
                borderWidth: 1,
                hoverBackgroundColor: '#EEE',
                hoverBorderColor: '#DDD',
                data: data
            });
            this.setState({ chartData: chartData });
        });
    }

    render() {
        return (
            <Card title="Devices Model" loading={this.state.loading}>
                <Bar data={this.state.chartData} />
            </Card>
        );
    }
}
