import React, { Component } from 'react';
import { Card, Button, DatePicker, Checkbox, Icon, Tooltip } from 'antd';
import moment from 'moment';
import { exportCSVFile } from '../api/CSVUtils';
import * as urls from '../configs/urls';
import { TextCircle } from '../components';
import { getDataFromAPI } from '../api/DbAPI';
import appSettings from '../configs/appSettings';

const { RangePicker } = DatePicker;

const headers = {
    id: 'id',
    name: 'name',
    username: 'email',
    createdAt: 'createdAt',
    phone: 'phone'
};

const fieldsOptions = [
    { label: 'ID', value: 'id' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'username' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Phone Number', value: 'phone' }
];

const checkedFields = ['id', 'name', 'username', 'createdAt', 'phone'];

export default class ExportUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            checkedFields: checkedFields,

            dates: [
                moment(moment(), appSettings.dateFormat).subtract(1, 'M'),
                moment(moment(), appSettings.dateFormat)
            ]
        };
    }
    onClickExport() {
        this.getUsers().then(() => {
            let formattedHeader = this.getFormattedHeader();
            let itemsFormatted = this.getFormattedUsers();
            let fileName = appSettings.APP_NAME + ' Users';
            if (this.state.dates[0] !== undefined) {
                fileName +=
                    ' - ' +
                    this.state.dates[0].format(appSettings.dateFormat) +
                    ' ' +
                    this.state.dates[1].format(appSettings.dateFormat);
            } else {
                fileName += moment().format(appSettings.dateFormat);
            }
            exportCSVFile(formattedHeader, itemsFormatted, fileName);
        });
    }

    getFormattedUsers() {
        let itemsFormatted = [];
        this.state.users.forEach(item => {
            let formattedUser = {};
            this.state.checkedFields.forEach(checkedFieldsItem => {
                formattedUser[checkedFieldsItem] = item[checkedFieldsItem];
            });
            itemsFormatted.push(formattedUser);
        });
        return itemsFormatted;
    }
    getFormattedHeader() {
        let formattedHeader = {};
        this.state.checkedFields.forEach(checkedFieldsItem => {
            if (checkedFieldsItem === 'email') {
                checkedFieldsItem = 'username';
            }
            formattedHeader[checkedFieldsItem] = headers[checkedFieldsItem];
        });
        return formattedHeader;
    }

    onChangeFields = checkedFields => {
        this.setState({
            checkedFields
        });
    };
    onDataRangeChange = (dates, dateStrings) => {
        this.setState({
            dates: dates
        });
        console.log('From: ', dates[0], ', to: ', dates[1]);
    };
    getUsers() {
        let startDate = undefined;
        let endDate = undefined;
        let url = urls.getUsers;
        if (this.state.dates[0] !== undefined) {
            startDate = this.state.dates[0].format(appSettings.serverDateFormat);
            endDate = this.state.dates[1].format(appSettings.serverDateFormat);
            url += '?startDate=' + startDate + '&endDate=' + endDate;
        }
        return getDataFromAPI(url).then(data => {
            this.setState({
                users: data
            });
        });
    }
    render() {
        return (
            <Card
                title="Export users"
                extra={
                    <div className="row justify-center">
                        <Button className="margin-horizontal" onClick={() => this.onClickExport()}>
                            CSV
                        </Button>
                    </div>
                }
            >
                <div className="row margin-vertical align-center">
                    <h4 className="margin-horizontal">Date</h4>
                    <RangePicker
                        defaultValue={this.state.dates}
                        format={appSettings.dateFormat}
                        onChange={this.onDataRangeChange}
                    />
                    <Tooltip title="Export only users that is in the date range. If both dates is empty the table will contain all users">
                        <Icon type="question-circle" className="margin-horizontal" />
                    </Tooltip>
                </div>
                <div className="row margin-vertical align-center">
                    <h4 className="margin-horizontal">Fields</h4>

                    <Checkbox.Group
                        options={fieldsOptions}
                        value={this.state.checkedFields}
                        onChange={this.onChangeFields}
                    />
                </div>
            </Card>
        );
    }
}
