import React, { Component } from 'react';
import { Progress, Card } from 'antd';
import { getDiskUsage } from '../api/DbAPI';

export default class DiskStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diskStatus: [],
            loading: true
        };
    }
    componentDidMount() {
        this.getRam();
    }

    getRam() {
        getDiskUsage().then(data => {
            this.setState({ diskStatus: data, loading: false });
        });
    }
    render() {
        return (
            <Card title="Disk Status" loading={this.state.loading}>
                <div className="column">
                    <div className="row">
                        <h3 className="flex-item">
                            {this.convertBytesToGigabytes(this.state.diskStatus.used)}
                        </h3>
                        <h3 className="flex-item text-end">
                            {this.convertBytesToGigabytes(this.state.diskStatus.total)}
                        </h3>
                    </div>
                    <Progress
                        className="margin-vertical"
                        status="active"
                        percent={this.convertNumberToPercentage(
                            this.state.diskStatus.used,
                            this.state.diskStatus.total
                        )}
                    />
                </div>
            </Card>
        );
    }

    convertBytesToGigabytes = value => {
        if (value == undefined) return 0;
        return parseFloat(Number(value) / Math.pow(1024, 3)).toFixed(0) + ' GB';
    };

    convertNumberToPercentage = (value, total) => {
        if (value == undefined || total == undefined) return parseInt(0);
        return parseInt((Number(value) / Number(total)) * 100);
    };
}
