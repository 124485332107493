import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom";
import {Icon} from 'antd';
import firebase from './api/firebase'
// import gapi from './configs/GoogleAuth'

import './App.css';
import { Store } from './store'
import { MainContainer } from './containers'
import ReactChartkick from 'react-chartkick'
import Chart from 'chart.js'
import AppRouter, {ContainerRouter} from './configs/Router';

import 'antd/dist/antd.css';
import './styles/Colors.css';
import './styles/Components.css';
// import './styles/test.scss'

ReactChartkick.addAdapter(Chart);

class App extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
		}
	}
	componentDidMount(){
		this.setState({loading: false})
	}
	render() {
		return (
			<Provider store={Store}>
				<Router>
					<ContainerRouter />
				</Router>
			</Provider>
		);
	}

	showMainContainer = () =>{
		if(this.state.loading) return <Icon type="loading"/>
		else{
			return <MainContainer/>
		}
	}
}

export default App;
