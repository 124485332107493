import React, { Component } from 'react';

export default class TextCircle extends Component {
    render() {
        return (
            <div className="text-circle-container">
                <div {...this.props} className="text-circle-circle">
                    <div className="text-circle-children">
                        <h2 className="text-circle-text">{this.props.children}</h2>
                    </div>
                </div>
                <h3 className="text-circle-text text-circle-title ">{this.props.title}</h3>
            </div>
        );
    }
}
