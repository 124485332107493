import { Button, Menu, Icon, Badge, Dropdown } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppRouter from '../configs/Router';
import { connect } from 'react-redux';
import { updateCurrentAppId } from '../actions';
import * as firebase from 'firebase';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import '../styles/MainContainer.css';

class MainContainer extends Component {
    getAppsName = () => {
        return this.props.appList.apps.map((item, index) => {
            return (
                <Menu.Item key={index}>
                    <Icon type="mobile" /> {item.name}{' '}
                </Menu.Item>
            );
        });
    };
    appDropdown = () => {
        return <Menu onClick={e => this.onClickAppButton(e.key)}>{this.getAppsName()}</Menu>;
    };

    onClickAppButton = index => {
        this.props.updateCurrentAppId(Number(index));
    };
    logout = ()=>{
        firebase.auth().signOut();
    }
    render() {
        return (
            <div className="main-container">
                <div className="sidebar" data-simplebar>
                    <div className="header sidebar-header">
                        <img src={require('../assets/images/logo.png')} alt="logo" />
                    </div>
                    <Menu className="sidebar-menu">
                        <Menu.Item>
                            <Link to="/">
                                <Icon type="home" /> Overview{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/server">
                                <Icon type="hdd" /> Server{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/users">
                                <Icon type="usergroup-add" /> Users{' '}
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>
                            <Link to="/settings">
                                <Icon type="setting" /> Settings{' '}
                            </Link>
                        </Menu.Item> */}
                    </Menu>
                </div>
                <div className="main-container-right">
                    <header className="header header-tools">
                        <div className="flex-item row">
                            <div className="header sidebar-header">
                                
                            </div>
                            {/* <Dropdown overlay={this.appDropdown}>
                                <Button>
                                    <Icon type="mobile" />{' '}
                                    {this.props.appList.apps[this.props.appList.currentAppId].name}
                                </Button>
                            </Dropdown> */}
                        </div>

                
                        <div className="flex-item justify-end row" >
                            <Icon type="logout" onClick={this.logout} className="logout-button"/>
                        </div>
                    </header>
                    <div className="content" data-simplebar>
                        <AppRouter />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    value: store.main.value,
    appList: store.appList
});

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentAppId: value => {
            dispatch(updateCurrentAppId(value));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainContainer);
