import axios from 'axios';
import * as urls from '../configs/urls';

export function getFromAPI(url) {
    return axios({
        method: 'get',
        timeout: 60 * 4 * 1000,
        url: url,
        headers: { 'Access-Control-Allow-Origin': '*' }
    });
}

export function getDataFromAPI(url) {
    return new Promise((resolve, reject) => {
        getFromAPI(url)
            .then(data => {
                // console.log(data, url);
                resolve(data.data.results);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
}

export function getDbPromise(url) {
    return new Promise((resolve, reject) => {
        return getDataFromAPI(url).then(data => {
            resolve(data);
        });
    });
}

export function getDevicesCount() {
    return getDbPromise(urls.getDevicesCount);
}
export function getDevicesCountActive() {
    return getDbPromise(urls.getDevicesCountActive + '?lastDays=30');
}
export function getCPUFrequency() {
    return getDbPromise(urls.getCPUFrequency);
}
export function getCPUUsage() {
    return getDbPromise(urls.getCPUUsage);
}
export function getRAMUsage() {
    return getDbPromise(urls.getRAMUsage);
}
export function getDiskUsage() {
    return getDbPromise(urls.getDiskUsage);
}
export function getServerInfo() {
    return getDbPromise(urls.getServerInfo);
}
export function getDevicesRegisteredByModel(model) {
    return getDbPromise(urls.getDevicesCount + '?model=' + model);
}
export function getDevicesActiveByModel(model) {
    return getDbPromise(urls.getDevicesCountActive + '?model=' + model + '&lastDays=30');
}
