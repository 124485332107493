import React, { Component } from 'react';
import { Card } from 'antd';
import * as urls from '../configs/urls';
import { TextCircle } from '../components';
import { getDataFromAPI } from '../api/DbAPI';

export default class UsersStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsersStatus: true,
            usersCount: 0,
            usersCountActive: 0
        };
    }
    componentDidMount() {
        let intervalId = setInterval(this.getData(), 600000);
        this.setState({intervalId: intervalId});
        this.getData();
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    getData() {
        const promises = [this.getUsersCount(), this.getUsersCountActive()];
        Promise.all(promises).then(data => {
            this.setState({
                loadingUsersStatus: false,
                usersCount: data[0],
                usersCountActive: data[1]
            });
        });
    }
    getUsersCount() {
        return getDataFromAPI(urls.getUsersCount);
    }
    getUsersCountActive() {
        return getDataFromAPI(urls.getUsersCountActive + '?lastDays=30');
    }

    render() {
        return (
            <div>
                <Card
                    title="Users Status"
                    loading={this.state.loadingUsersStatus}
                    className="flex-item"
                >
                    <div className="row justify-center align-center">
                        <TextCircle title="Registred users">{this.state.usersCount}</TextCircle>
                        <TextCircle title="Active users">{this.state.usersCountActive}</TextCircle>
                    </div>
                </Card>
            </div>
        );
    }
}
