import { types } from '../actions'

const initialState = {
	apps: [
		{
			name: "Ponto Ativo",
			id: 0,
		},
		{
			name: "SEG Connect",
			id: 1,
		},
		{
			name: "Contatto",
			id: 2,
		},

	],
	currentAppId: 0,
};

const appListReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.ADD_APP:
			return {
				...state,
				apps: [...state.apps, {name: action.value, id: state.apps.length}]
			};
		case types.REMOVE_APP:
			return state.apps.filter(({ id }) => action.id !== id);
		case types.UPDATE_CURRENT_APP_ID:
			return {
				...state,
				currentAppId: action.value
			};
		default:
			return state;
	}
};

export default appListReducer;