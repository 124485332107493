import React, { Component } from 'react'

export default class TextRow extends Component {
  render() {
    return (
      <div className="row">
            <div className="flex-item row">
                {this.props.left}
            </div>
            <div className="flex-item justify-end row">
                {this.props.right}
            </div>
      </div>
    )
  }
}
