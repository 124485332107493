import React, { Component } from 'react';
import { Card, Col, Row } from 'antd';
import { TextCircle } from '../components';
import { getServerInfo } from '../api/DbAPI';
import moment from 'moment';

export default class ServerInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processes: 0,
            data: [],
            loading: true
        };
    }
    componentDidMount() {
        let intervalId = setInterval(() => this.getData(), 3000);
        this.setState({intervalId: intervalId});
        this.getData();
       
    }
    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    getData() {
        getServerInfo().then(data => {
            this.setState({ data: data, loading: false }, () =>{
                this.getUptime();
            });
            
        });
    }
    getUptime = () => {
        let currentTime = moment();
        let boottime = moment(this.state.data.boottime);
        var ms = moment(currentTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(boottime, 'DD/MM/YYYY HH:mm:ss'));
        var d = moment.duration(ms);
        return d.hours() + ':' + d.minutes() + ':' + d.seconds();
    };
    render() {
        return (
            <Card title="Server Info" loading={this.state.loading}>
                <div className="column justify-center align-center">
                    {/* <div className="column">
                        <h3>Up Time</h3>
                        <h1>{this.getUptime()}</h1>
                    </div> */}

                    <TextCircle title="Processes">{this.state.data.processes}</TextCircle>
                </div>
            </Card>
        );
    }
}
