// export const server_api = 'http://192.168.1.6:8889';
export const server_api = 'http://cpro38760.publiccloud.com.br:8888/api';
export const getUsers = server_api + '/users';
export const getUsersCount = server_api + '/users/count/registered';
export const getUsersCountActive = server_api + '/users/count/active';
export const getDevicesCount = server_api + '/devices/count/registered';
export const getDevicesCountActive = server_api + '/devices/count/active';
export const getCPUFrequency = server_api + '/server/cpu/frequency';
export const getServerInfo = server_api + '/server';
export const getCPUUsage = server_api + '/server/cpu/used';
export const getRAMUsage = server_api + '/server/memory';
export const getDiskUsage = server_api + '/server/disk';
