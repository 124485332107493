import React, { Component } from 'react';
import { Card } from 'antd';
import { Line } from 'react-chartjs-2';
import { getDevicesCount, getDevicesCountActive } from '../api/DbAPI';
import { TextCircle } from '../components';

export default class DevicesStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesCount: 0,
            devicesCountActive: 0,
            loading: true
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData() {
        const promises = [getDevicesCount(), getDevicesCountActive()];
        Promise.all(promises).then(data => {
            this.setState({
                devicesCount: data[0],
                devicesCountActive: data[1],
                loading: false
            });
        });
    }
    render() {
        return (
            <Card title="Device Status" loading={this.state.loading}>
                <div className="row justify-center align-center">
                    <TextCircle title="Devices Registred">{this.state.devicesCount}</TextCircle>
                    <TextCircle title="Devices Active">{this.state.devicesCountActive}</TextCircle>
                </div>
            </Card>
        );
    }
}
