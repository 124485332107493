export const types = {
	//APP
	ADD_APP: 'ADD_APP',
	REMOVE_APP: 'REMOVE_APP',
	UPDATE_CURRENT_APP_ID: 'UPDATE_CURRENT_APP_ID', 
	
	//USER
	UPDATE_USERS: "UPDATE_USERS",
	UPDATE_USERS_REGISTRED: "UPDATE_USERS_REGISTRED",
	UPDATE_USERS_ACTIVE: "UPDATE_USERS_ACTIVES",
}

export const updateCurrentAppId = value => ({
    type: types.UPDATE_CURRENT_APP_ID,
    value: value
});

export const updateUsersRegistred = value => ({
	type: types.UPDATE_USERS_REGISTRED,
	value: value
})
export const updateUsersActive = value => ({
	type: types.UPDATE_USERS_ACTIVE,
	value: value
})
