import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import moment from 'moment';
import { exportCSVFile } from '../api/CSVUtils';
import * as urls from '../configs/urls';
import { TextCircle, ExportUsers, UsersStatus } from '../components';
import { getDataFromAPI } from '../api/DbAPI';
import appSettings from '../configs/appSettings';
import { Line } from 'react-chartjs-2';

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsersActivity: true,
            usersRegistredByPeriod: [],
            usersActiveByPeriod: []
        };
        this.getUsersRegistredByPeriod();
        this.getUsersActiveByPeriod();
    }

    componentDidMount(props) {}
    getUsers() {
        let startDate = undefined;
        let endDate = undefined;
        let url = urls.getUsers;
        if (this.state.dates[0] !== undefined) {
            startDate = this.state.dates[0].format(appSettings.serverDateFormat);
            endDate = this.state.dates[1].format(appSettings.serverDateFormat);
            url += '?startDate=' + startDate + '&endDate=' + endDate;
        }
        return getDataFromAPI(url).then(data => {
            this.setState({
                users: data
            });
        });
    }

    getUsersActiveByPeriod() {
        this.getUsersByPeriod(urls.getUsersCountActive).then(data => {
            this.setState(
                {
                    usersActiveByPeriod: data,
                    loadingUsersActivity: false
                },
                () => {
                    let users = this.state.usersActiveByPeriod;
                    let arr = this.state.usersActiveByPeriod[0];
                    arr = arr.sort((x, y) => {
                        return moment(x).format('X') - moment(y).format('X');
                    });
                    users[0] = arr;
                    this.setState({ usersActiveByPeriod: users });
                }
            );
        });
    }
    getUsersRegistredByPeriod() {
        this.getUsersByPeriod(urls.getUsersCount).then(data => {
            this.setState(
                {
                    usersRegistredByPeriod: data,
                    loadingUsersActiveActivity: false
                },
                () => {
                    let users = this.state.usersRegistredByPeriod;
                    let arr = this.state.usersRegistredByPeriod[0];
                    arr = arr.sort((x, y) => {
                        return moment(x).format('X') - moment(y).format('X');
                    });
                    users[0] = arr;
                    this.setState({ usersRegistredByPeriod: users });
                }
            );
        });
    }
    getUsersByPeriod(url) {
        return new Promise((resolve, reject) => {
            let usersData = [[], []];
            let entryDate = moment()
                .subtract(12, 'M')
                .startOf('M')
                .startOf('D');
            for (let index = 0; index < 12; index++) {
                let startDate = entryDate
                    .clone()
                    .add(index, 'M')
                    .format(appSettings.serverDateFormat);
                let endDate = entryDate
                    .clone()
                    .add(index + 1, 'M')
                    .format(appSettings.serverDateFormat);

                getDataFromAPI(url + '?startDate=' + startDate + '&endDate=' + endDate).then(
                    data => {
                        usersData[0].push(moment(startDate).format('MMM YYYY'));
                        usersData[1].push(data);
                    }
                );
            }
            resolve(usersData);
        });
    }

    render() {
        return (
            <div>
                <h1>Users</h1>
                <Row gutter={12}>
                    <Col span={8}>
                        <UsersStatus />
                    </Col>
                    <Col span={16}>
                        <ExportUsers />
                    </Col>
                </Row>
            </div>
        );
    }
}
